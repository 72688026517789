
<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners"  @close="onClose"
    title="添加快递规则"
    :visible.sync="visible"
    width="600px"
    >

      <el-form ref="form" :model="row" :rules="rules" size="mini" label-width="120px"      >
<el-row>
        <el-col :span="24">
                <el-form-item label="规则名称" prop="name">
              <el-input v-model="row.name" placeholder="请输入规则名称" clearable
                :style="{width: '200px'}"></el-input>
            </el-form-item>
        </el-col>
            </el-row>

<el-row>
        <el-col :span="24">
                <el-form-item label="规则顺序(优先级)" prop="priority">
              <el-input v-model="row.priority" placeholder="请输入规则顺序" clearable
                :style="{width: '200px'}"></el-input>
            </el-form-item>
        </el-col>
            </el-row>


            <el-row>

        <el-col >
                <el-form-item label="客户" prop="customer">

                <el-select v-model="row.customerOperator"
                clearable
                placeholder="请选择"
                style="width:100px;margin-right:10px">
                    <el-option
                          v-for="item in enums['Operator']"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value">
                        </el-option>
                </el-select>

                <el-select ref="customer"
                                v-model="row.customer"
                                prop="customer"
                                placeholder="请选择客户"
                                clearable
                                filterable
                                multiple
                                :filter-method="listWmsCustomer"
                            >
                            <el-option
                                v-for="item in optionsOfWmsCustomer"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                            ></el-option>
                </el-select>
            </el-form-item>
        </el-col>
            </el-row>

            <el-row>

        <el-col :span="24">
            <el-form-item label="发货模板" prop="expressTemplateId">
                <el-select ref="expressTemplateId"
                                v-model="row.expressTemplateId"
                                prop="expressTemplateId"
                                placeholder="请选择发货模板"
                                clearable
                                filterable
                                :filter-method="listWmsExpressTemplate"
                            >
                            <el-option
                                v-for="item in optionsOfWmsExpressTemplate"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                            ></el-option>
                </el-select>
            </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        </el-row>

      </el-form>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getEnums, uploadFile } from "@/api/common"
import { addWmsExpressRule } from "@/api/wms/wmsExpressRule"

import { listWmsExpressTemplate } from "@/api/wms/wmsExpressTemplate"
import { listWmsCustomer } from "@/api/wms/wmsCustomer"


export default {
  inheritAttrs: false,
  components: { },
  props: [],
  data() {
    return {
      visible: false,
      row: {
            priority: 100,
            name: null,
            customer: [],
            customerOperator: 0,
            expressTemplateId: null,
            expressTemplateName: null,
      },
      rules: {
            name: [
                { required: true, message: '请输入规则名称!'},
            ],
            expressTemplateId: [
                { required: true, message: '请输入发货模板!'},
            ],
      },
        enums: {},
        enumMaps: {},
        optionsOfWmsExpressTemplate: [],
        optionsOfWmsCustomer: [],

    }
  },
  computed: {},
  watch: {},
  created() {
    this.initEnums()
    this.listWmsExpressTemplate()
    this.listWmsCustomer()
  },
  mounted() {},
  methods: {
      initEnums() {
          let names = []
          names.push('Operator')
          const param = {
              names: names
          }
          getEnums(param).then(res=>{
              if(res.code===200) {
                this.enums = res.data.enums
                Object.keys(this.enums).forEach(key => {
                  let map = {}
                  this.enums[key].forEach(obj => map[obj.value] = obj.name)
                  this.enumMaps[key] = map
                })
              } else {
                  this.$message.error(res.message)
              }
          })
    },
    onOpen() {
        this.visible = true
    },
    onClose() {
    },
    close() {
      this.$refs['form'].resetFields()
      this.visible = false
    },
    success(result) {
      this.$emit('success', result)
    },

    listWmsExpressTemplate(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsExpressTemplate(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsExpressTemplate = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsCustomer(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsCustomer(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsCustomer = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    upload(param){
        const formData = new FormData()
        formData.append('file', param.file)
        uploadFile(formData).then(res => {
            if(res.code === 200){
                param.onSuccess(res.data.url)
                this.$message.success("上传成功")
            }else{
                this.$message.error(res.message)
            }
        }).catch(response => {
            console.log(response)
            this.$message.error('上传失败')
            param.onError()
        })
    },
    handleConfirm() {
      this.$refs['form'].validate(valid => {
        if (!valid) return;
        const param = this.row
        this.loading = true
        addWmsExpressRule(param).then(res => {
            this.loading = false
            if(res.code===200) {
                this.$message.success(res.message)
                this.success(res.data)
                this.close()
            } else {
                this.$message.error(res.message)
            }
        }).catch(err=>{
            this.loading = false
        })
      })
    },
  }
}

</script>
<style scoped>
.el-select {
    width: 200px;
}
.avatar {
    max-width: 200px;
    max-height: 200px
}
</style>
