import request from '@/api/request.js'

/**
 * @Description: 快递规则
 */

// 分页查询
export function pageWmsExpressRule(data) {
    return request({
        url: '/api/wmsExpressRule/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsExpressRule(data) {
    return request({
        url: '/api/wmsExpressRule/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsExpressRule(data) {
    return request({
        url: '/api/wmsExpressRule/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsExpressRule(data) {
    return request({
        url: '/api/wmsExpressRule/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsExpressRule(data) {
    return request({
        url: '/api/wmsExpressRule/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsExpressRule(data) {
    return request({
        url: '/api/wmsExpressRule/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsExpressRule(data) {
    return request({
        url: '/api/wmsExpressRule/batchDelete',
        method: 'POST',
        data
    })
}

